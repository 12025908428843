<template>
    <v-container class="pa-0">
        <v-alert type="info" dense class="my-4">{{ $t('views.reports.layout_info') }}</v-alert>
        <ReportHeader
            :title="$t('views.reports.frequencies.title')"
            :description="$t('views.reports.frequencies.description')"
            icon="fas fa-user"
        >
            <v-form ref="form" v-model="isFormValid" @submit.prevent="submit">
                <v-row>
                    <v-col>
                        <DateInput
                            :label="$t('views.reports.commons.form.from.label')"
                            :placeholder="$t('views.reports.commons.form.from.placeholder')"
                            :hint="$t('views.reports.commons.form.from.hint')"
                            v-model="params.from"
                            outputFormat="YYYY-MM-DDTHH:mm:ssZ"
                        />
                    </v-col>
                    <v-col>
                        <DateInput
                            :label="$t('views.reports.commons.form.to.label')"
                            :placeholder="$t('views.reports.commons.form.to.placeholder')"
                            :hint="$t('views.reports.commons.form.to.hint')"
                            v-model="params.to"
                            outputFormat="YYYY-MM-DDTHH:mm:ssZ"
                        />
                    </v-col>
                </v-row>

                <v-btn
                    color="success"
                    type="submit"
                    :loading="loading"
                    :disabled="!isFormValid"
                >{{ $t('actions.confirm') }}</v-btn>
            </v-form>
        </ReportHeader>

        <ReportContent
            :headers="computedHeaders"
            :reportResponse="reportResponse"
            :loading="loading"
            :hasError="hasError"
            :count="true"
            :pagination="{}"
            :model="CustomerHistoryRecord"
            hide-count
            disable-export
        >
            <!-- @export="exportReport" -->
            <template #group-item="{ item, tdClass }">
                <td :class="tdClass" class="text-center">{{ item.operations['cpy:count'] || item.operations['sum:cpy:count'] || '' }}</td>
            </template>
            <template #label-depth-2="{ item }">
                <v-tooltip right>
                    <template #activator="{ on, attrs }">
                        <span v-on="on" v-bind="attrs">
                            <router-link class="inherit--text" :to="{ name: 'customers.details', params: { id: item.key } }"><b>{{ item.label }}</b></router-link>
                        </span>
                    </template>
                    <span>{{ $t('views.reports.frequencies.content.see_customer') }}</span>
                </v-tooltip>
            </template>
        </ReportContent>

    </v-container>
</template>
<script>
import HasErrorHandlerMixin            from '@/mixins/HasErrorHandlerMixin'
import RateColor                       from '@/shared/mixins/RateColor'
import DateInput                       from '@/shared/components/inputs/DateInput'
import ReportHeader                    from '../commons/ReportHeader'
import ReportContent                   from '../commons/ReportContent'
import CustomerHistoryRecordRepository from '@/repositories/CustomerHistoryRecordRepository'
import ReportResponse                  from '@/models/ReportResponse'
import CustomerHistoryRecord           from '@/models/CustomerHistoryRecord'

// const REPORT_OPERATIONS = ''

export default {
    mixins    : [ HasErrorHandlerMixin, RateColor ],
    components: { DateInput, ReportHeader, ReportContent },
    data      : vm => ({
        CustomerHistoryRecord,
        params: {
            from    : vm.$route.query.from     || '',
            to      : vm.$route.query.to       || '',
        },
        lastParams: {
            from    : undefined,
            to      : undefined,
        },

        loading       : false,
        hasError      : false,
        isFormValid   : true,
        reportResponse: new ReportResponse,
    }),
    async created() {
        await this.submit()
    },
    methods: {
        async submit() {
            await this.fetchData()
        },
        async fetchData() {
            this.loading      = true
            this.hasError     = false
            this.dataResponse = {}

            if (this.$refs.form && !this.$refs.form.validate()) {
                this.loading  = false
                return
            }

            // Atualiza parâmetros da busca na query para poder voltar com o navegador
            this.$router.push({ query: this.computedQuery })

            // Logo em seguida faz request para listar
            this.reportResponse = await CustomerHistoryRecordRepository.fetchReport(this.computedPagination, this.computedParams)
                .catch(this.preErrorHandler)

            this.lastParams = this.$lodash.cloneDeep(this.params)
            this.loading    = false
        },
        // Será implementado na [FID-1178] depois da [FID-463]
        // async exportReport() {
        //     // [TEMP][FID-463] Implementação provisória, será melhorada na FID-463
        //     this.loading  = true
        //     this.hasError = false

        //     // Valida parâmetros atuais
        //     if (this.$refs.form && !this.$refs.form.validate()) {
        //         this.loading = false
        //         return
        //     }

        //     // Logo em seguida faz request para exportar
        //     await CustomerHistoryRecordRepository.fetchReport(this.computedPagination, { ...this.computedParams, export: true })
        //         .catch(this.preErrorHandler)

        //     this.lastParams = this.$lodash.cloneDeep(this.params)
        //     this.loading    = false
        // },
        preErrorHandler(e) {
            this.hasError   = true
            this.errorHandler(e)
        },
    },
    computed: {
        computedQuery() {
            return this.params
        },
        computedPagination() {
            return {}
        },
        computedParams() {
            return {
                ...this.params,
                count   : true,
                group_by: 'field:company.id,sum:cpy:count;field:customer_id,cpy:count;time:created_at:1:DAY',
            }
        },
        computedHeaders() {
            return [
                { text: this.$t('views.reports.commons.header.label') },
                { text: this.$t('views.reports.frequencies.header.frequency_sum'), width: '1%', align: 'center' },
                // { text: this.$t('views.reports.commons.header.count'), align: 'right' },
            ]
        },
    },
}
</script>

<style scoped>
.show_customer {
    color: #304FFE !important;
}
</style>