var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c(
        "v-alert",
        { staticClass: "my-4", attrs: { type: "info", dense: "" } },
        [_vm._v(_vm._s(_vm.$t("views.reports.layout_info")))]
      ),
      _c(
        "ReportHeader",
        {
          attrs: {
            title: _vm.$t("views.reports.frequencies.title"),
            description: _vm.$t("views.reports.frequencies.description"),
            icon: "fas fa-user"
          }
        },
        [
          _c(
            "v-form",
            {
              ref: "form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submit($event)
                }
              },
              model: {
                value: _vm.isFormValid,
                callback: function($$v) {
                  _vm.isFormValid = $$v
                },
                expression: "isFormValid"
              }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("DateInput", {
                        attrs: {
                          label: _vm.$t(
                            "views.reports.commons.form.from.label"
                          ),
                          placeholder: _vm.$t(
                            "views.reports.commons.form.from.placeholder"
                          ),
                          hint: _vm.$t("views.reports.commons.form.from.hint"),
                          outputFormat: "YYYY-MM-DDTHH:mm:ssZ"
                        },
                        model: {
                          value: _vm.params.from,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "from", $$v)
                          },
                          expression: "params.from"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("DateInput", {
                        attrs: {
                          label: _vm.$t("views.reports.commons.form.to.label"),
                          placeholder: _vm.$t(
                            "views.reports.commons.form.to.placeholder"
                          ),
                          hint: _vm.$t("views.reports.commons.form.to.hint"),
                          outputFormat: "YYYY-MM-DDTHH:mm:ssZ"
                        },
                        model: {
                          value: _vm.params.to,
                          callback: function($$v) {
                            _vm.$set(_vm.params, "to", $$v)
                          },
                          expression: "params.to"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "success",
                    type: "submit",
                    loading: _vm.loading,
                    disabled: !_vm.isFormValid
                  }
                },
                [_vm._v(_vm._s(_vm.$t("actions.confirm")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ReportContent", {
        attrs: {
          headers: _vm.computedHeaders,
          reportResponse: _vm.reportResponse,
          loading: _vm.loading,
          hasError: _vm.hasError,
          count: true,
          pagination: {},
          model: _vm.CustomerHistoryRecord,
          "hide-count": "",
          "disable-export": ""
        },
        scopedSlots: _vm._u([
          {
            key: "group-item",
            fn: function(ref) {
              var item = ref.item
              var tdClass = ref.tdClass
              return [
                _c("td", { staticClass: "text-center", class: tdClass }, [
                  _vm._v(
                    _vm._s(
                      item.operations["cpy:count"] ||
                        item.operations["sum:cpy:count"] ||
                        ""
                    )
                  )
                ])
              ]
            }
          },
          {
            key: "label-depth-2",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { right: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "span",
                                _vm._g(_vm._b({}, "span", attrs, false), on),
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "inherit--text",
                                      attrs: {
                                        to: {
                                          name: "customers.details",
                                          params: { id: item.key }
                                        }
                                      }
                                    },
                                    [_c("b", [_vm._v(_vm._s(item.label))])]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "views.reports.frequencies.content.see_customer"
                          )
                        )
                      )
                    ])
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }